import React, { useRef } from 'react';
import gsap from 'gsap';
import VisibilitySensor from 'react-visibility-sensor';

export default ({ children }) => {
	const inner = useRef(null);
	const onVisibilityChange = (isVisible) => {
		if (isVisible) {
			gsap.to(inner.current, 1, {
				opacity: 1,
				y: 0,
			});
		} else {
			gsap.to(inner.current, 1, {
				opacity: 0,
				y: 25,
			});
		}
	};
	return (
		<VisibilitySensor onChange={onVisibilityChange} partialVisibility>
			<div
				ref={inner}
				style={{
					opacity: 0,
					transform: 'translate(0px, 25px)',
				}}
			>
				{children}
			</div>
		</VisibilitySensor>
	);
};
