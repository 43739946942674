import React from 'react';

import FooterStyle from './style';

import instagram from '../../../images/social/instagram.svg';
import linkedin from '../../../images/social/linkedin.svg';
import facebook from '../../../images/social/facebook.svg';

export default () => (
	<FooterStyle>
		<div className="footer-top">
			<div className="col">
				<h2 className="brand">5FOLD</h2>
				<div className="partner-badge-container">
					<div className="g-partnersbadge" data-agency-id="9453865514"></div>
				</div>
			</div>
			<div className="col">
				<a
					href="https://www.instagram.com/5foldagency/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={instagram} alt="instagram" />
				</a>
				<a
					href="https://www.linkedin.com/company/5fold-agency/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={linkedin} alt="linkedin" />
				</a>
				<a
					href="https://www.facebook.com/the5foldagency/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={facebook} alt="facebook" />
				</a>
			</div>
		</div>
		<div className="footer-bottom">
			&copy; {new Date().getFullYear()}, 5FOLD AGENCY. ALL RIGHTS RESERVED.
		</div>
	</FooterStyle>
);
