import styled from 'styled-components';

export default styled.section`
	padding: 4rem;
	margin: 0 auto;
	position: relative;
	max-width: 64rem;
	h2 {
		font-size: 2.5rem;
		line-height: 1.25;
		text-align: center;
		font-weight: 300;
		strong {
			font-weight: 700;
		}
		a {
			font-weight: 700;
			color: inherit;
			text-decoration: none;
			display: inline-block;
			border-bottom: 6px solid ${(p) => p.theme.color.primary};
			&:hover {
				border-bottom: 6px solid ${(p) => '#f5f501'};
			}
		}
	}
	@media ${(p) => p.theme.device.mobile} {
		padding: 4rem 1rem;
		h2 {
			font-size: 1.25rem;
		}
	}
`;
