import React from 'react';
import { Link } from 'gatsby';

import { StyledNavMenu, MenuPane, MenuToggle } from './style';

import Hamburger from './images/burger.inline.svg';

import instagram from '../../../images/social/instagram.svg';
import linkedin from '../../../images/social/linkedin.svg';
import facebook from '../../../images/social/facebook.svg';

export default ({ isMenuVisible, setMenuVisible }) => {
	return (
		<StyledNavMenu>
			<MenuToggle isActive={isMenuVisible}>
				<span>5FOLD</span>{' '}
				<button
					onClick={() => {
						setMenuVisible(!isMenuVisible);
					}}
				>
					<Hamburger />
				</button>
			</MenuToggle>
			<MenuPane isVisible={isMenuVisible}>
				<div className={`inner ${isMenuVisible ? 'show' : 'hide'}`}>
					<ul>
						<li>
							<Link to="/" tabIndex={isMenuVisible ? 0 : -1}>
								home
							</Link>
						</li>
						<li>
							<Link to="/services" tabIndex={isMenuVisible ? 0 : -1}>
								services
							</Link>
						</li>
						<li>
							<Link to="/work" tabIndex={isMenuVisible ? 0 : -1}>
								work
							</Link>
						</li>
						<li>
							<Link to="/team" tabIndex={isMenuVisible ? 0 : -1}>
								team
							</Link>
						</li>
						<li>
							<Link to="/contact" tabIndex={isMenuVisible ? 0 : -1}>
								contact
							</Link>
						</li>
					</ul>
					<div className="social">
						<a
							href="https://www.instagram.com/5foldagency/"
							tabIndex={isMenuVisible ? 0 : -1}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={instagram} alt="instagram" />
						</a>
						<a
							href="https://www.linkedin.com/company/5fold-agency/"
							tabIndex={isMenuVisible ? 0 : -1}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={linkedin} alt="linkedin" />
						</a>
						<a
							href="https://www.facebook.com/the5foldagency/"
							tabIndex={isMenuVisible ? 0 : -1}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={facebook} alt="facebook" />
						</a>
					</div>
				</div>
			</MenuPane>
		</StyledNavMenu>
	);
};
