import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { withController } from 'react-scroll-parallax';

import GlobalStyle from '../../../style/global';
import theme from '../../../style/theme';

import Layout from '../layout';
import SEO from '../seo';

const Page = ({ children, title, desc, parallaxController }) => {
	useEffect(() => {
		window.requestAnimationFrame(() => {
			parallaxController.update();
		});
	});
	return (
		<ThemeProvider theme={theme}>
			<>
				<SEO title={title} description={desc} />
				<GlobalStyle />
				<Layout>{children}</Layout>
			</>
		</ThemeProvider>
	);
};

export default withController(Page);
