import React from 'react';
import styled, { css } from 'styled-components';

const BlurWrap = styled.div`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&::after {
		background-color: black;
		${(p) =>
			p.blur
				? css`
						opacity: 0.8;
						z-index: 100;
						transition: opacity ease 400ms, z-index 0ms;
				  `
				: css`
						opacity: 0;
						z-index: -1000;
						transition: opacity ease 400ms, z-index 0ms 400ms;
				  `};
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export default ({ children, blur, setBlur }) => {
	return (
		<BlurWrap blur={blur} onClick={() => setBlur(false)}>
			{children}
		</BlurWrap>
	);
};
