import styled from 'styled-components';

export default styled.footer`
	background-color: ${(p) => p.theme.color.dark};
	color: ${(p) => p.theme.color.lightest};
	font-size: 0.75rem;

	.footer-top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 4rem;

		.col {
			color: ${(p) => p.theme.color.primary};
			&:nth-child(1) {
				flex: 1 1;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				h2.brand {
					font-weight: 400;
					font-family: ${(p) => p.theme.font.display};
					font-size: 5rem;
					padding-top: 9px;
					margin-right: 0.25em;
				}
			}

			&:nth-child(2) {
				flex: 1 1;
				display: flex;
				justify-content: flex-end;
				img {
					width: 1.75rem;
					height: 1.75rem;
					margin: 0.625rem;
					display: block;
				}
			}
		}
	}

	.footer-bottom {
		background-color: #000000;
		text-align: center;
		color: ${(p) => p.theme.color.primary};
		padding: 0.5rem 4rem;
	}

	@media ${(p) => p.theme.device.tablet} {
		height: unset;
		.footer-top {
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			.col {
				&:nth-child(1) {
					flex: 0 0 50%;
					order: 1;
				}
				&:nth-child(2) {
					flex: 1;
					order: 3;
				}
				&:nth-child(3) {
					flex: 0 0 50%;
					order: 2;
					a {
						&:last-child {
							img {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	@media ${(p) => p.theme.device.small} {
		.footer-top {
			padding: 1rem;

			.col {
				&:nth-child(1) {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2.brand {
						margin-right: 0;
					}
				}
			}
		}

		.footer-bottom {
			padding: 1rem;
		}
	}
`;
