import React from 'react';

import CloserStyle from './style';
import FadeInOnVisibility from '../fade-in-on-visibility';

export default ({ children }) => {
	return (
		<CloserStyle>
			<FadeInOnVisibility>{children}</FadeInOnVisibility>
		</CloserStyle>
	);
};
