import styled, { css } from 'styled-components';

export const StyledNavMenu = styled.div`
	position: relative;
	z-index: 500;
`;

export const MenuToggle = styled.div`
	position: fixed;
	display: flex;
	align-items: flex-start;
	top: 2rem;
	right: 2rem;
	z-index: 20;
	background-color: ${(p) => p.theme.color.primary};
	font-family: ${(p) => p.theme.font.display};
	font-size: 2.25rem;
	box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.16);
	height: 3rem;
	span {
		padding-top: 9px;
		padding-left: 1rem;
		display: inline-block;
	}
	button {
		background-color: transparent;
		border-width: 0;
		padding: 2px;
		cursor: pointer;
		outline: none;
		margin: 0 0.5rem;
		svg {
			height: 40px;
			width: 40px;
			margin-top: 2px;
			display: block;
		}
	}
	#hamburger {
		& > * {
			transition: all ease 300ms;
			transform-origin: 50% 50%;
		}
		${(p) =>
			p.isActive &&
			css`
				#top-rect {
					transform: rotate(135deg) translateY(9px);
				}
				#bottom-rect {
					transform: rotate(-135deg) translateY(-9px);
				}
				#middle-rect {
					opacity: 0;
				}
			`};
	}
	@media ${(p) => p.theme.device.mobile} {
		top: 1rem;
		right: 1rem;
	}
`;

export const MenuPane = styled.div`
	background-color: ${(p) => p.theme.color.secondary};
	width: ${(p) => (p.isVisible ? '250px' : '0px')};
	height: ${(p) => (p.isVisible ? '400px' : '0px')};
	position: fixed;
	z-index: 10;
	top: 2rem;
	right: 2rem;
	transition: height 300ms ease, width 300ms ease;
	box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.16);
	.inner {
		opacity: ${(p) => (p.isVisible ? '1.0' : '0')};
		color: ${(p) => p.theme.color.lightest};
		padding-top: 4.5rem;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		&.show {
			opacity: 1;
			transition: opacity 300ms ease 300ms;
			display: block;
		}
		&.hide {
			opacity: 0;
			* {
				display: none;
			}
		}
		li {
			margin-bottom: 1rem;
		}
		a {
			color: ${(p) => p.theme.color.lightest};
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		img {
			width: 1.5rem;
			height: 1.5rem;
			margin: 1.5rem 0.5rem;
		}
	}
	@media ${(p) => p.theme.device.mobile} {
		top: 1rem;
		right: 1rem;
	}
`;
