import React, { useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import VisibilitySensor from 'react-visibility-sensor';
import FadeInOnVisibility from '../fade-in-on-visibility';

const OpenerStyle = styled.section`
	padding: 6rem 4rem;
	position: relative;
	margin: 0 auto;
	max-width: 64rem;
	.short {
		max-width: 40rem;
	}
	.hand {
		width: 400px;
		position: absolute;
		top: -2rem;
		right: -24rem;
		img {
			width: 100%;
		}
		@media ${(p) => p.theme.device.mobile} {
			top: unset;
			bottom: 0;
			left: 55%;
			width: 50%;
		}
	}
	button {
		border: 2px solid ${(p) => p.theme.color.secondary};
		color: ${(p) => p.theme.color.secondary};
		font-family: ${(p) => p.theme.font.display};
		font-size: 1.25rem;
		border-radius: 1rem;
		padding: 0.25rem 2rem 0;
		margin-right: 2rem;
		margin-top: 2rem;
		cursor: pointer;
		transition: all ease 300ms;
		background-color: ${(p) => p.theme.color.lightest};
		@media ${(p) => p.theme.device.mobile} {
			width: 50%;
		}
		&:hover {
			color: ${(p) => p.theme.color.lightest};
			background-color: ${(p) => p.theme.color.secondary};
		}
	}
	@media ${(p) => p.theme.device.mobile} {
		padding: 1rem 1rem 2rem 1rem;
	}
`;

const TitleStyle = styled.h2`
	font-size: 1.5rem;
	color: ${(p) => p.theme.color.secondary};
	font-weight: 700;
	z-index: 200;
	margin-bottom: 6rem;
	position: relative;
	@media ${(p) => p.theme.device.mobile} {
		margin-bottom: 1rem;
		line-height: 3rem;
	}
`;

const SubtitleStyle = styled.h3`
	color: ${(p) => p.theme.color.ghostDarken};
	font-size: 6rem;
	font-family: ${(p) => p.theme.font.display};
	display: inline-block;
	position: absolute;
	top: 4rem;
	left: 1rem;
	z-index: 100;
	overflow: hidden;
	@media ${(p) => p.theme.device.mobile} {
		font-size: 4rem;
		top: 0;
		left: 0;
		position: relative;
		margin-bottom: 0.5rem;
	}
`;

const ContentStyle = styled.div`
	max-width: 50rem;
	z-index: 200;
	position: relative;
	h2 {
		font-size: 2rem;
		line-height: 1.15;
		margin-bottom: 3rem;
	}
`;

export default ({ title, subtitle, children }) => {
	const heading = useRef(null);
	const onVisibilityChange = (isVisible) => {
		if (isVisible) {
			gsap.to(heading.current, 1, {
				opacity: 1,
				x: 0,
			});
		} else {
			gsap.to(heading.current, 1, {
				opacity: 0,
				x: 200,
			});
		}
	};
	return (
		<FadeInOnVisibility>
			<OpenerStyle>
				<TitleStyle>{title}</TitleStyle>
				<VisibilitySensor onChange={onVisibilityChange} partialVisibility>
					<SubtitleStyle
						ref={heading}
						style={{ opacity: 0, transform: 'translate(200px, 0px)' }}
					>
						{subtitle}
					</SubtitleStyle>
				</VisibilitySensor>
				{children && <ContentStyle>{children}</ContentStyle>}
			</OpenerStyle>
		</FadeInOnVisibility>
	);
};
