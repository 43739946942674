import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}
	body {
		font-family: ${(p) => p.theme.font.body};
		background-color: ${(p) => p.theme.color.lightest};
		color: ${(p) => p.theme.color.darkest};
		p {
			line-height: 1.5;
		}
		button, a {
			outline: none;
		}
	}
	strong {
		font-weight: 700;
	}
	::selection {
		background-color: ${(p) => p.theme.color.primary};
	}
`;

export default GlobalStyle;
