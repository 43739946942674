import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import NavMenu from '../../common/nav-menu';
import Footer from '../../common/footer';
import BlurOverlay from '../../common/blur-overlay';

const Main = styled.main`
	max-width: 100vw;
	overflow: hidden;
`;

export default ({ children }) => {
	const [isMenuVisible, setMenuVisible] = useState(false);
	const handleKeyDown = (e) => {
		if (e.keyCode === 27) {
			if (isMenuVisible) {
				setMenuVisible(false);
			}
		}
	};
	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});
	return (
		<>
			<NavMenu
				isMenuVisible={isMenuVisible}
				setMenuVisible={setMenuVisible}
				onKeyDown={handleKeyDown}
			/>
			<BlurOverlay blur={isMenuVisible} setBlur={setMenuVisible}>
				<Main>{children}</Main>
				<Footer />
			</BlurOverlay>
		</>
	);
};
