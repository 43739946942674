export default {
	color: {
		primary: '#ffff01',
		primaryDarken: '#f8f801',
		secondary: '#0101ff',
		lightest: '#ffffff',
		ghost: '#f2f2f2',
		ghostDarken: '#eeeeee',
		grey: '#707001',
		dark: '#3F4041',
		darkest: '#010101',
	},
	font: {
		body: `'Work Sans', sans-serif`,
		display: `'alternate-gothic-no-3-d', sans-serif`,
	},
	device: {
		small: `(max-width: 499px)`,
		mobile: `(max-width: 768px)`,
		tablet: `(max-width: 960px)`,
	},
};
